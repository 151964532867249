




































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ShareButton extends Vue {
  @Prop() private icon!: string;
  @Prop() private href!: string;
}

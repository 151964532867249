































import { Component, Prop, Vue } from 'vue-property-decorator';
import ShareButton from './ShareButton.vue';

@Component({
  components: {
    ShareButton,
  },
})
export default class SharePanel extends Vue {
  @Prop() publisher!: Publisher;
  @Prop() story!: Story;

  visible = false;

  open() {
    this.visible = true;
  }

  close() {
    this.visible = false;
  }

  getTrackableURL(
    medium: string,
    publisherId: string,
    storyId: string,
    escape = false,
    url?: string
  ): string {
    const utmContent = [publisherId, storyId].filter(val => val).join('-');
    const params = [
      'utm_source=webplayer',
      `utm_medium=${medium}`,
      'utm_campaign=1',
      utmContent ? `utm_content=${utmContent}` : null,
    ]
      .filter(val => val)
      .join('&');
    if (escape) {
      return `${url}?${encodeURIComponent(params)}`;
    }
    return `${url}?${params}`;
  }

  getPublicationText(name: string): string {
    return encodeURIComponent(
      `Listen to the latest news${name ? ` from ${name}` : ''}.`
    );
  }

  getPublicationTextWithURL(name: string, url: string): string {
    return encodeURIComponent(
      `Listen to the latest news${name ? ` from ${name}` : ''} - ${url}.`
    );
  }

  getTwitterLink(
    publisher: Publisher,
    story: Story,
    shareUrl?: string
  ): string {
    return `https://twitter.com/intent/tweet?text=${this.getPublicationText(
      publisher.name
    )}&url=${this.getTrackableURL(
      'twitter',
      publisher.id,
      story.id,
      true,
      shareUrl
    )}`;
  }

  getFacebookLink(
    publisher: Publisher,
    story: Story,
    shareUrl?: string
  ): string {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.getTrackableURL(
      'facebook',
      publisher.id,
      story.id,
      false,
      shareUrl
    )}`;
  }

  getEmailLink(publisher: Publisher, story: Story, shareUrl?: string): string {
    const trackableUrl = this.getTrackableURL(
      'email',
      publisher.id,
      story.id,
      false,
      shareUrl
    );
    return `mailto:?to=&subject=${this.getPublicationText(
      publisher.name
    )}&body=${this.getPublicationTextWithURL(publisher.name, trackableUrl)}`;
  }

  getSmsLink(publisher: Publisher, story: Story, shareUrl?: string): string {
    const userAgent = navigator.userAgent.toLowerCase();
    const trackableUrl = this.getTrackableURL(
      'sms',
      publisher.id,
      story.id,
      true,
      shareUrl
    );

    if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
      return `sms:&body=${this.getPublicationTextWithURL(
        publisher.name,
        trackableUrl
      )}`;
    }
    return `sms:?body=${this.getPublicationTextWithURL(
      publisher.name,
      trackableUrl
    )}`;
  }
}























































































































































import { Component, Inject, Ref, Vue } from 'vue-property-decorator';

import { config } from '../../config';
import ControlButton from '../ControlButton.vue';
import PlaybackScrubber from '../PlaybackScrubber.vue';
import SharePanel from '../SharePanel.vue';
import { PlayerController, PlayerState } from '@/services/player-controller';
import { SubscriptionManager } from '@/core/utils';

@Component({
  components: {
    ControlButton,
    PlaybackScrubber,
    SharePanel,
  },
})
export default class PlayerV3Legacy extends Vue
  implements VueComponentLifecycle {
  state: PlayerState = {};
  currentTime = 0;
  totalTime = 0;
  buffered = 0;

  @Inject() private controller!: PlayerController;
  @Ref() private sharePanel!: SharePanel;
  private subscriptions!: SubscriptionManager;

  get ariaContentLabel(): string {
    return `from ${this.state.channelName}: ${this.trackTitle}`;
  }

  get darkTheme(): boolean {
    return this.state.settings
      ? this.state.settings.design.theme === 'dark'
      : false;
  }

  get disableControls(): boolean {
    return !!(this.state.loading || this.state.suspended);
  }

  get controlIcon(): string {
    return this.playing ? 'pause' : 'play';
  }

  get playing(): boolean {
    return !!this.state.playing;
  }

  buttonLabel(icon: string): string {
    return config.aria.labels[icon];
  }

  get shareUrl(): string {
    return this.state.shareUrl || '#';
  }

  get showPrevious(): boolean {
    if (this.state.settings) {
      return !!(
        this.state.hasPrevious &&
        this.currentTime < this.state.settings.controls.replayButtonAfter
      );
    } else {
      return true;
    }
  }

  get sponsoredText(): string {
    return config.text.sponsored;
  }

  get trackTitle(): string {
    return this.state.story ? this.state.story.content.title : '';
  }

  get trackSubtitle(): string {
    return this.state.story && this.state.story.content.author
      ? `by ${this.state.story.content.author}`
      : '';
  }

  get thumbnailSrc(): string {
    return this.state.publisher
      ? this.state.publisher.display.thumbnail.small ||
          this.state.publisher.display.thumbnail.default
      : '';
  }

  /* lifecycle */

  created() {
    this.subscriptions = new SubscriptionManager();
    this.state = { ...this.controller.state };
  }

  mounted() {
    this.subscriptions.watch<Partial<PlayerState>>(
      this.controller.updates$,
      update => {
        this.state = { ...this.state, ...update };

        if (update.story) {
          this.currentTime = 0;
        }

        if (update.audioDuration) {
          this.totalTime = update.audioDuration;
        }

        if (update.adDuration) {
          this.totalTime = update.adDuration;
          this.buffered = 0;
        }

        if (update.buffered) {
          this.buffered = update.buffered;
        }

        if (update.suspended === false) {
          this.totalTime = this.state.audioDuration || 0;
          this.buffered = this.state.buffered || 0;
        }
      }
    );
    this.subscriptions.watch<number>(
      this.controller.currentTime$,
      currentTime => {
        this.currentTime = currentTime;
      }
    );
    this.subscriptions.watch<number>(
      this.controller.remainingAdTime$,
      remainingTime => {
        this.currentTime = Math.max(
          (this.state.adDuration || 0) - remainingTime,
          0
        );
      }
    );
  }

  destroyed(): void {
    this.subscriptions.close();
  }
}
